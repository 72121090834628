"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const Sentry = __importStar(require("@sentry/browser"));
Sentry.init({
    environment: window.sentryEnvironment,
    dsn: window.sentryDsn,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.captureConsoleIntegration({
            levels: ['error', 'warn'],
        }),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
        Sentry.thirdPartyErrorFilterIntegration({
            filterKeys: ['driveto-sentry-frontend-javascript-bundle-key'],
            behaviour: 'drop-error-if-contains-third-party-frames',
        }),
    ],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: parseFloat(window.sentryErrorSampleRate),
    release: window.sentryRelease,
    tracesSampleRate: parseFloat(window.sentryFrontendSampleRate),
    sendDefaultPii: true,
    ignoreErrors: [
        'Can\'t find variable: DOT',
        'can\'t redefine non-configurable property "userAgent"',
        'window.webkit.messageHandlers.translationHelper.postMessage',
        'Sender: Failed to get initial state. Please report this bug.',
        'Cannot read properties of undefined (reading \'domInteractive\')',
        'gtag is not defined',
        'Can\'t find variable: gtag',
        '[locatorjs]',
        'TronWeb: \'tronWeb.sidechain\' is deprecated and may be removed in the future.',
    ],
    denyUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
        // Firefox extensions
        /^resource:\/\//i,
    ],
});
